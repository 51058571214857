import LogHelper from '@/generic/helpers/LogHelper';
import IReportResponse from '@/report/models/IReportResponse';
import ITeamReportResponse from '@/report/models/ITeamReportResponse';
import { BaseService } from './baseService';
import EnvService from './EnvService';
import ReportListResponse from '@/report/models/ReportList/ReportListResponse';
import QuestionCompareResponse from '@/report/models/Demographic/IQuestionCompareResponse';
import DashboardData from '@/report/models/DashboardData';
import QuestionAnswerScore from '@/report/models/Demographic/IQuestionAnswerScore';
import ProjectExportData from '@/report/models/IProjectExportData';
import DemographicQuestionAnswer from '@/report/models/IDemographicQuestionAnswer';
export class ReportService extends BaseService {
    private reportEndpoint = `${EnvService.getEnvironment().reportApiEndpoint}`;

    public async getReports(prefLanguageCode: string): Promise<ReportListResponse> {
        const url = `${this.reportEndpoint}/personal-reports?language=${prefLanguageCode}`;
        const response = await this.get(url);
        return response.data;
    }

    public async getTeamReports(prefLanguageCode: string, token: string, projectId: number): Promise<ReportListResponse> {
        const url = `${this.reportEndpoint}/reports?language=${prefLanguageCode}&project_id=${projectId}`;
        try {
            const response = await this.get(url, null, {
                headers: {
                    Authorization: `TeamToken ${token}`,
                },
            });

            return response.data;
        } catch (e) {
            LogHelper.error('Failed to fetch project report data.', e);
            return null;
        }
    }

    public async getReportDetail(surveyId: number, reportAlias: string): Promise<IReportResponse> {
        const reports = [
            { reportAlias: 'psi_personal', knowledgeModelAlias: 'fearless' },
            { reportAlias: 'los_personal', knowledgeModelAlias: 'fearless' },
            { reportAlias: 'ievp_personal', knowledgeModelAlias: 'ievp' },
            { reportAlias: 'tem_personal', knowledgeModelAlias: 'team-energie' },
            { reportAlias: 'baseline', knowledgeModelAlias: 'kantor' },
            { reportAlias: 'psi_trial', knowledgeModelAlias: 'fearless' },
            { reportAlias: 'pit_personal', knowledgeModelAlias: 'pit' },
            { reportAlias: 'psi_coaching', knowledgeModelAlias: 'fearless' },
        ];

        const report = reports.find((x) => x.reportAlias === reportAlias);
        if (!report) {
            LogHelper.error('Failed to fetch report data.');
            return null;
        }

        const url = `${this.reportEndpoint}/${report.knowledgeModelAlias}/web/personal?survey_id=${surveyId}&report_alias=${report.reportAlias}`;

        try {
            const response = await this.get(url);

            return response.data as IReportResponse;
        } catch (e) {
            LogHelper.error('Failed to fetch report data.', e);
            return null;
        }
    }

    public async getTeamReport(projectId: number, token: string, reportAlias: string): Promise<ITeamReportResponse> {
        const migratedReports = [
            { reportAlias: 'psi', knowledgeModelAlias: 'fearless' },
            { reportAlias: 'psi_anonymous', knowledgeModelAlias: 'fearless' },
            { reportAlias: 'los', knowledgeModelAlias: 'fearless' },
            { reportAlias: 'psi_organization', knowledgeModelAlias: 'fearless' },
            { reportAlias: 'pss_anonymous', knowledgeModelAlias: 'fearless' },
            { reportAlias: 'pss', knowledgeModelAlias: 'fearless' },
            { reportAlias: 'novo_nordisk', knowledgeModelAlias: 'fearless' },
            { reportAlias: 'tem', knowledgeModelAlias: 'team-energie' },
            { reportAlias: 'tem_stress', knowledgeModelAlias: 'team-energie' },
            { reportAlias: 'novo_nordisk', knowledgeModelAlias: 'fearless' },
            { reportAlias: 'intact', knowledgeModelAlias: 'kantor' },
            { reportAlias: 'baseline_aggregate', knowledgeModelAlias: 'kantor' },
            { reportAlias: 'ievp', knowledgeModelAlias: 'ievp' },
            { reportAlias: 'pit', knowledgeModelAlias: 'pit' },
            { reportAlias: 'ievp_extended', knowledgeModelAlias: 'ievp' },
            { reportAlias: 'rjc', knowledgeModelAlias: 'just-culture' },
            { reportAlias: 'epsi', knowledgeModelAlias: 'fearless' },
            { reportAlias: 'epsi_c', knowledgeModelAlias: 'fearless' },
            { reportAlias: 'epsi_anonymous', knowledgeModelAlias: 'fearless' },
        ];

        const report = migratedReports.find((x) => x.reportAlias === reportAlias);
        if (!report && reportAlias) {
            LogHelper.error('Failed to fetch report data.');
            return null;
        }

        const url = `${this.reportEndpoint}/${report.knowledgeModelAlias}/web/group?project_id=${projectId}&report_alias=${report.reportAlias}`;
        try {
            const response = await this.get(url, null, {
                headers: {
                    Authorization: `TeamToken ${token}`,
                },
            });
            return response.data as ITeamReportResponse;
        } catch (e) {
            LogHelper.error('Failed to fetch team report data.', e);
            return null;
        }
    }

    public async getQuestionCompareScores(
        projectId: number,
        questionLabel1: string,
        questionLabel2: string,
        token: string,
    ): Promise<QuestionCompareResponse> {
        const url = `${this.reportEndpoint}/fearless/demographics/${projectId}/demographic-question-compare?question_label1=${questionLabel1}&question_label2=${questionLabel2}`;

        try {
            const response = await this.get(url, null, {
                headers: {
                    Authorization: `TeamToken ${token}`,
                },
            });
            return response.data as QuestionCompareResponse;
        } catch (e) {
            LogHelper.error('Failed to fetch question compare scores data.');
            return null;
        }
    }

    public async getQuestionAnswerScores(projectId: number, questionLabel: string, token: string): Promise<QuestionAnswerScore> {
        const url = `${this.reportEndpoint}/fearless/demographics/${projectId}/question-compare?question_label=${questionLabel}`;

        try {
            const response = await this.get(url, null, {
                headers: {
                    Authorization: `TeamToken ${token}`,
                },
            });
            return response.data as QuestionAnswerScore;
        } catch (e) {
            LogHelper.error('Failed to fetch question - answer scores data.');
            return null;
        }
    }

    public async getGeneralAnswerScores(projectId: number, questionLabel: string, token: string): Promise<QuestionAnswerScore> {
        const url = `${this.reportEndpoint}/fearless/demographics/${projectId}/general-question-compare?question_label=${questionLabel}`;

        try {
            const response = await this.get(url, null, {
                headers: {
                    Authorization: `TeamToken ${token}`,
                },
            });
            return response.data as QuestionAnswerScore;
        } catch (e) {
            LogHelper.error('Failed to fetch general answer scores data.');
            return null;
        }
    }

    public async getDashboardInfo(orgId: number, token?: string, projectIds?: number[]): Promise<DashboardData> {
        let url = `${this.reportEndpoint}/fearless/dashboard?organization_id=${orgId}`;

        if (projectIds) {
            projectIds.forEach((id) => {
                url += `&id=${id}`;
            });
        }

        try {
            const response = await this.get(url, null, {
                headers: {
                    Authorization: `TeamToken ${token}`,
                },
            });
            return response.data as DashboardData;
        } catch (e) {
            LogHelper.error('Failed to fetch dashboard data.', e);
            return null;
        }
    }

    public async getProjectData(projectId: number, token?: string): Promise<ProjectExportData> {
        const url = `${this.reportEndpoint}/project-data/${projectId}?team_token=${token}`;

        try {
            const response = await this.get(url, null, {
                headers: {
                    Authorization: `TeamToken ${token}`,
                },
            });
            return response.data as ProjectExportData;
        } catch (e) {
            LogHelper.error('Failed to fetch dashboard data.', e);
            return null;
        }
    }

    public async getDemographicQuestionAnswers(projectId: number, token?: string): Promise<DemographicQuestionAnswer[]> {
        const url = `${this.reportEndpoint}/project-data/${projectId}/demographic?team_token=${token}`;

        try {
            const response = await this.get(url, null, {
                headers: {
                    Authorization: `TeamToken ${token}`,
                },
            });
            return response.data;
        } catch (e) {
            LogHelper.error('Failed to fetch demographic question answers', e);
            return null;
        }
    }
}
